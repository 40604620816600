.shadow_0_0_20 {
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.161);
  -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.161);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.161);
}
.dropshadow_0_0_20 {
  filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.161));
}
.shadow_0_0_10 {
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.161);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.161);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.161);
}
@font-face {
  font-family: "foco";
  src: url('/assets/fonts/Foco_W_Rg.woff2') format('woff2'), url('/assets/fonts/Foco_W_Rg.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: "foco";
  src: url('/assets/fonts/Foco_W_It.woff2') format('woff2'), url('/assets/fonts/Foco_W_It.woff') format('woff');
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "foco";
  src: url('/assets/fonts/Foco_W_Lt.woff2') format('woff2'), url('/assets/fonts/Foco_W_Lt.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "foco";
  src: url('/assets/fonts/Foco_W_LtIt.woff2') format('woff2'), url('/assets/fonts/Foco_W_LtIt.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "foco";
  src: url('/assets/fonts/Foco_W_Bd.woff2') format('woff2'), url('/assets/fonts/Foco_W_Bd.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "foco";
  src: url('/assets/fonts/Foco_W_BdIt.woff2') format('woff2'), url('/assets/fonts/Foco_W_BdIt.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
.fontRegular {
  font-family: "foco";
  font-weight: 400;
}
.fontRegularItalic {
  font-family: "foco";
  font-weight: 400;
  font-style: italic;
}
.fontMedium {
  font-family: "foco";
  font-weight: 500;
}
.fontMediumItalic {
  font-family: "foco";
  font-weight: 500;
  font-style: italic;
}
.fontBold {
  font-family: "foco";
  font-weight: 700;
}
.fontBoldItalic {
  font-family: "foco";
  font-weight: 700;
  font-style: italic;
}
.fontLight {
  font-family: "foco";
  font-weight: 300;
}
.fontLightItalic {
  font-family: "foco";
  font-weight: 300;
  font-style: italic;
}
.fontSize16 {
  font-size: 1rem;
}
.fontSize18 {
  font-size: 1.125rem;
}
.fontSize19 {
  font-size: 1.1704rem;
}
.fontSize20 {
  font-size: 1.25rem;
}
.fontSize22 {
  font-size: 1.375rem;
}
.fontSize28 {
  font-size: 1.75rem;
}
.fontSize34 {
  font-size: 2.125rem;
}
.fontRegular24 {
  font-family: "foco";
  font-weight: 400;
  font-size: 1.5rem;
}
.fontRegular20 {
  font-family: "foco";
  font-weight: 400;
  font-size: 1.25rem;
}
.fontRegular18 {
  font-family: "foco";
  font-weight: 400;
  font-size: 1.125rem;
}
.fontRegular16 {
  font-family: "foco";
  font-weight: 400;
  font-size: 1rem;
}
.fontBold16 {
  font-family: "foco";
  font-weight: 700;
  font-size: 1rem;
}
.fontLight16 {
  font-family: "foco";
  font-weight: 300;
  font-size: 1rem;
}
.fontLight18 {
  font-family: "foco";
  font-weight: 300;
  font-size: 1.25rem;
}
.fontLight22 {
  font-family: "foco";
  font-weight: 300;
  font-size: 1.375rem;
}
.carousel {
  background-color: #FFFFFF;
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.161);
  -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.161);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.161);
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 50px;
  height: 420px;
}
.carousel .carousel-item .sliderImage {
  height: 420px;
  overflow: hidden;
  position: relative;
}
.carousel .carousel-item .sliderImage img {
  position: absolute;
  width: 100%;
  max-width: none;
}
.carousel .carousel-item .slideritem-headline {
  font-family: "foco";
  font-weight: 700;
  font-size: 1.125rem;
  color: #1A171B;
  letter-spacing: 0px;
}
.carousel .carousel-item .slideritem-titel {
  font-family: "foco";
  font-weight: 400;
  font-style: italic;
  font-size: 1.75rem;
  line-height: 2.125rem;
  color: #707070;
}
.carousel .carousel-item .slideritem-content {
  font-family: "foco";
  font-weight: 300;
  font-size: 1.25rem;
  color: #1A171B;
  line-height: 1.75rem;
}
.carousel .carousel-item .slideritem-link {
  font-family: "foco";
  font-weight: 400;
  font-style: italic;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 1px;
}
.carousel .carousel-indicators {
  bottom: -50px;
}
.carousel .carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.161);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.161);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.161);
  background-color: #FFFFFF;
  opacity: 1;
  border: 0;
}
.carousel .carousel-indicators li.active {
  background-color: #707070;
}
.carousel .carousel-control-prev {
  margin-left: -40px;
  width: 40px;
  background-color: #FFFFFF;
  border-radius: 40px 0 0 40px;
  opacity: 1;
  height: 80px;
  margin-top: auto;
  margin-bottom: auto;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.161);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.161);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.161);
}
.carousel .carousel-control-prev .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNC44ODUiIGhlaWdodD0iMjYuMzIiIHZpZXdCb3g9IjAgMCAxNC44ODUgMjYuMzIiPg0KICA8ZGVmcz4NCiAgICA8c3R5bGU+DQogICAgICAuY2xzLTEgew0KICAgICAgICBmaWxsOiBub25lOw0KICAgICAgICBzdHJva2U6ICNlM2U0ZTQ7DQogICAgICAgIHN0cm9rZS1saW5lY2FwOiByb3VuZDsNCiAgICAgICAgc3Ryb2tlLW1pdGVybGltaXQ6IDEwOw0KICAgICAgICBzdHJva2Utd2lkdGg6IDJweDsNCiAgICAgIH0NCiAgICA8L3N0eWxlPg0KICA8L2RlZnM+DQogIDxnIGlkPSJHcnVwcGVfNTQiIGRhdGEtbmFtZT0iR3J1cHBlIDU0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxLjQxNCAyNC45MDYpIHJvdGF0ZSgtOTApIj4NCiAgICA8bGluZSBpZD0iTGluaWVfMiIgZGF0YS1uYW1lPSJMaW5pZSAyIiBjbGFzcz0iY2xzLTEiIHgxPSIxMi4wNTYiIHkxPSIxMi4wNTYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDExLjQzNSAwKSIvPg0KICAgIDxsaW5lIGlkPSJMaW5pZV8zIiBkYXRhLW5hbWU9IkxpbmllIDMiIGNsYXNzPSJjbHMtMSIgeTE9IjExLjgwNyIgeDI9IjExLjQzNSIvPg0KICA8L2c+DQo8L3N2Zz4NCg==");
}
.carousel .carousel-control-next {
  margin-right: -40px;
  width: 40px;
  background-color: #FFFFFF;
  border-radius: 0 40px 40px 0;
  opacity: 1;
  height: 80px;
  margin-top: auto;
  margin-bottom: auto;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.161);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.161);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.161);
}
.carousel .carousel-control-next .carousel-control-next-icon {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNC44ODUiIGhlaWdodD0iMjYuMzIiIHZpZXdCb3g9IjAgMCAxNC44ODUgMjYuMzIiPg0KICA8ZGVmcz4NCiAgICA8c3R5bGU+DQogICAgICAuY2xzLTEgew0KICAgICAgICBmaWxsOiBub25lOw0KICAgICAgICBzdHJva2U6ICNlM2U0ZTQ7DQogICAgICAgIHN0cm9rZS1saW5lY2FwOiByb3VuZDsNCiAgICAgICAgc3Ryb2tlLW1pdGVybGltaXQ6IDEwOw0KICAgICAgICBzdHJva2Utd2lkdGg6IDJweDsNCiAgICAgIH0NCiAgICA8L3N0eWxlPg0KICA8L2RlZnM+DQogIDxnIGlkPSJHcnVwcGVfNTQiIGRhdGEtbmFtZT0iR3J1cHBlIDU0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNjEuOTg3IDEwNDMuNDA2KSByb3RhdGUoLTkwKSI+DQogICAgPGxpbmUgaWQ9IkxpbmllXzIiIGRhdGEtbmFtZT0iTGluaWUgMiIgY2xhc3M9ImNscy0xIiB4MT0iMTIuMDU2IiB5Mj0iMTIuMDU2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMDI5LjkzNSA2My40MDEpIi8+DQogICAgPGxpbmUgaWQ9IkxpbmllXzMiIGRhdGEtbmFtZT0iTGluaWUgMyIgY2xhc3M9ImNscy0xIiB4Mj0iMTEuNDM1IiB5Mj0iMTEuODA3IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMDE4LjUgNjMuNjUxKSIvPg0KICA8L2c+DQo8L3N2Zz4NCg==");
}
@media only screen and (max-width: 991px) {
  .carousel {
    height: 580px;
    margin-left: 40px;
    margin-right: 40px;
  }
  .carousel .carousel-item .sliderImage {
    height: 220px;
    overflow: hidden;
    position: relative;
  }
  .carousel .carousel-item .sliderImage img {
    width: 100%;
  }
  .carousel .carousel-item .slideritem-titel {
    font-size: 1.375rem;
    line-height: 1.75rem;
  }
}
@media only screen and (max-width: 767px) {
  .carousel {
    height: 700px;
    margin-left: 25px;
    margin-right: 25px;
  }
  .carousel .carousel-control-prev {
    margin-left: -35px;
    width: 35px;
    border-radius: 35px 0 0 35px;
    height: 70px;
  }
  .carousel .carousel-control-next {
    margin-right: -35px;
    width: 35px;
    border-radius: 0 35px 35px 0;
    height: 70px;
  }
  .carousel .carousel-item .sliderImage {
    height: 220px;
    overflow: hidden;
    position: relative;
  }
  .carousel .carousel-item .sliderImage img {
    width: 100%;
  }
  .carousel .carousel-item .slideritem-titel {
    font-size: 1.375rem;
    line-height: 1.75rem;
  }
  .carousel .carousel-item .slideritem-content {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
}
